import { RaffleContext } from 'contexts/RaffleContext';
import { useEffect } from 'react';
import { useContext } from 'react';
import {
    deleteRaffleApi,
    getAllRafflesApi,
    getRaffleApi,
    saveRaffleApi,
    updateRaffleApi,
    loadParticipantsApi,
    addAwardApi,
    uploadImageAwardApi,
    uploadImageRaffleApi,
    notifyParticipantsApi,
    uploadRaffleAwardsApi,
    updateAwardApi
} from '../services/Raffle';

export const useRaffle = () => {
    const { raffles, setRaffles, raffle, setRaffle } = useContext(RaffleContext);

    const getRaffles = async () => {
        try {
            const response = await getAllRafflesApi();
            const rafflesRest = await response.data;
            setRaffles(rafflesRest);
        } catch (error) {
            console.log(error);
        }
    };

    const addRaffle = (newRaffle) => {
        return saveRaffleApi(newRaffle).then((res) => {
            setRaffle(res.data.raffle);
            return res.data.raffle._id;
        });
    };

    const getRaffle = (raffleId) => {
        getRaffleApi(raffleId).then((res) => {
            setRaffle(res.data.raffle);
        });
    };

    const updateRaffle = () => {
        updateRaffleApi(raffle).then((res) => {
            setRaffle(res.data.raffle);
        });
    };

    // TODO: rename this method
    const updateRaffleFromSwitch = (raffleItem) => {
        updateRaffleApi(raffleItem).then((res) => {
            setRaffle(res.data.raffle);
            getRaffles();
        });
    };

    const addAward = (raffleId, award) => {
        return addAwardApi(raffleId, award).then((res) => {
            setRaffle(res.data.raffle);
            return res.data.raffle.awards.pop()._id;
        });
    };

    const updateAward = (raffleId, awardOrder, award) => {
        return updateAwardApi(raffleId, awardOrder, award).then((res) => {
            setRaffle(res.data.raffle);
            return res.data.raffle.awards.pop()._id;
        });
    };

    const deleteRaffle = () => {
        deleteRaffleApi(raffle._id).then(() => {
            getRaffles();
        });
    };

    const loadParticipants = (slot) => {
        loadParticipantsApi(slot).then(() => {
            getRaffles();
        });
    };

    const updateRaffleAwards = (orderId, participant) => {
        uploadRaffleAwardsApi(raffle._id, orderId, participant).then((response) => {
            console.log(response);
        });
    };

    const uploadImageAward = (awardId, fileData) => {
        uploadImageAwardApi(raffle._id, awardId, fileData).then((res) => {
            setRaffle(res.data.raffle);
        });
    };

    const uploadImageRaffle = (raffleId, fileData) => {
        uploadImageRaffleApi(raffleId, fileData).then((res) => {
            setRaffle(res.data.raffle);
        });
    };

    const notifyParticipants = async (rafflesId) => {
        await notifyParticipantsApi(rafflesId);
        await getRaffle(raffleId);
    };

    useEffect(() => {
        if (!raffles.length) {
            getRaffles();
        }
    }, []);

    return {
        getRaffles,
        raffles,
        raffle,
        setRaffle,
        getRaffle,
        addRaffle,
        addAward,
        updateAward,
        updateRaffle,
        updateRaffleFromSwitch,
        deleteRaffle,
        loadParticipants,
        uploadImageAward,
        uploadImageRaffle,
        updateRaffleAwards,
        notifyParticipants
    };
};
